import { Outlet, useLocation, useNavigate } from '@remix-run/react';
import { Heading, Text } from '@venncity/block';
import { Button } from '@venncity/venn-ds';

export default function Login() {
  const location = useLocation();
  const navigate = useNavigate();

  const isEmailRoute = location.pathname === '/auth/login';

  return (
    <>
      <header className="text-center">
        <Heading>Welcome</Heading>
      </header>
      <div className="mt-10">
        <Outlet />
      </div>
      <footer className="mt-6 flex flex-col items-stretch text-xs">
        <div className="flex items-center">
          <hr className="m-0 w-full text-white" />
          <Text className="mx-4 " variant="p6">
            or
          </Text>
          <hr className="m-0 w-full text-white" />
        </div>
        <Button
          className="mt-6"
          ghost
          onClick={() =>
            navigate({
              pathname: isEmailRoute ? 'phone' : '/auth/login',
            })
          }
          shape="default"
          size="large">
          Login with {isEmailRoute ? 'phone' : 'email'}
        </Button>
        <Text className="mt-6" variant="p6">
          By logging in, I agree to the{' '}
          <a
            className="font-bold underline"
            href="https://legal.venn.city/app/privacy"
            rel="noopener noreferrer"
            target="_blank">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            className="font-bold underline"
            href="https://legal.venn.city/app/terms"
            rel="noopener noreferrer"
            target="_blank">
            T&C
          </a>
        </Text>
      </footer>
    </>
  );
}
